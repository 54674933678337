import React from 'react';

const Submenu1 = () => (
  <div className="container">
    <div className="row">
      <div className="col-12">
        <h1>Submenu 1</h1>
        <p>Welcome to my Gatsby site: Submenu 1 page.</p>
      </div>
    </div>
  </div>
);

export default Submenu1;
